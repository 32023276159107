import * as zoid from 'zoid/dist/zoid.frameworks.frame.min';

const ChatWidgetComponent = zoid.create({
  // The html tag used to render my component
  tag: 'chat-widget-component',

  // The url that will be loaded in the iframe or popup, when someone includes my component on their page
  url: process.env.CHAT_APP_URL,

  autoResize: {
    width: false,
    height: true,
    element: '#akin-chat-container',
  },

  // The size of the component on their page. Only px and % strings are supported
  dimensions: {
    width: '100%',
    height: '100%',
  },

  // The properties they can (or must) pass down to my component. This is optional.
  props: {
    theme: {
      type: 'string',
      required: false,
    },
    language: {
      type: 'string',
      required: false,
    },
    title: {
      type: 'string',
      required: false,
    },
    imageURL: {
      type: 'string',
      required: false,
    },
    spaceId: {
      type: 'string',
      required: false,
    },
  },

  // https://wtools.io/html-to-javascript-converter used to create "innerHTMLContent"
  prerenderTemplate: function containerTemplate({ doc }) {
    const html = doc.createElement('html');
    const innerHTMLContent =
      '<head><meta name="viewport" content="width=device-width, initial-scale=1"><style>body {display: flex;align-items: center;justify-content: center;height: 100vh;margin: 0px;}.loader{border: 4px solid #2e2e2e;border-radius: 50%;border-top: 4px solid #f3f3f3;width: 29px;height: 29px;animation: spin 1.2s linear infinite;-webkit-animation: spin 1.2s linear infinite; /* Safari */animation: spin 1s linear infinite;}/* Safari */@-webkit-keyframes spin {0% { -webkit-transform: rotate(0deg); }100% { -webkit-transform: rotate(360deg); }}@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}</style></head><body><div class="loader"></div></body>';

    html.innerHTML = innerHTMLContent;
    return html;
  },
});

export default ChatWidgetComponent;
